import s from './Hero.module.scss';
import Link from 'next/link';
import { FaWhatsapp, FaInstagram, FaBehance } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

export default function Hero() {
    const social = [
        {
            href: "https://wa.link/zzpw1g",
            iconComp: <FaWhatsapp size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
        {
            href: "https://www.instagram.com/whereismybrand.mx/",
            iconComp: <FaInstagram size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
        {
            href: "mailto:info@whereismybrand.mx",
            iconComp: <IoMdMail size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
        {
            href: "https://www.behance.net/whereismybrand?tracking_source=search_users%7Cwhere%20is%20my%20brand",
            iconComp: <FaBehance size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
    ];

    return (
        <section className={s['main-container']}>
            <video autoPlay={true} muted loop className={`${s.video} d-none d-md-block`}>
                <source src="/hero.mp4" type="video/mp4" />
                Tu navegador no soporta video
            </video>
            <video autoPlay={true} muted loop className={`${s.video} d-block d-md-none`}>
                <source src="/heroMobile.mp4" type="video/mp4" />
                Tu navegador no soporta video
            </video>
            <div id="hero" className={`${s['hero-content']}`}>
                <div className="container">
                    <div className={`row ${s['center-container']}`}>
                        <div className='col-md-12 text-center'>
                            <img src='/logobblack.png' alt="logo black" />
                            <h4 className='mt-4'>El mundo es de las marcas que saben dónde están.</h4>
                            <h4><strong>¿Ya te cuestionaste dónde está la tuya?</strong></h4>
                            <Link href="/servicios">
                                <button className='white-to-orange-btn mx-auto mt-4'>IR A SERVICIOS</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={s['hero-footer']}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 text-start'>
                                <div className={`${s['social-container']} mt-4`}>
                                    {
                                        social.map((s, i) => (
                                            <a
                                                key={i}
                                                href={s.href}
                                                target={"_blank"}
                                                rel="noreferrer"
                                                className='sn-icon'
                                                aria-label='redes sociales'
                                            >
                                                {s.iconComp}
                                            </a>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className='col-md-4 offset-md-1 text-center d-none d-md-block'>
                                <img className={`${s['scroll-icon']} mt-2`} alt="scroll icon" src='/hero-scroll-icon.svg' width={30} height={30} />
                            </div>
                            <div className='col-md-4 text-end'>
                                <button data-bs-toggle="modal" data-bs-target="#videoModal" className={`${s['play-button']}`}>PLAY</button>
                                {/*MODAL HERE */}
                                <div className="modal fade" tabIndex={-1} id="videoModal" data-bs-backdrop="static" data-bs-keyboard="false">
                                    <div className="modal-dialog modal-fullscreen bg-black">
                                        <div className="modal-content bg-black">
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-md-12 text-end' data-bs-dismiss="modal">
                                                        <i className="bi bi-x cursor" style={{ fontSize: '3rem' }}></i>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <video autoPlay controls className={s.videoDesktop}>
                                                            <source src="/hero.mp4" type='video/mp4'></source>
                                                        </video>
                                                        <video autoPlay controls className={s.videoMobile}>
                                                            <source src='/heroMobile.mp4' type='video/mp4'></source>
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    )
}