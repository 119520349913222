const PIXEL_ID = process.env.NEXT_PUBLIC_PIXEL_ID;
const ACCESS_TOKEN = process.env.NEXT_PUBLIC_FB_EVENTS_ACCESS_TOKEN;
import sha256 from 'sha256';

const url = `https://graph.facebook.com/v16.0/${PIXEL_ID}/events?access_token=${ACCESS_TOKEN}`;

export const FB_EVENTS = {
    pageView: 'PageView',
    lead: 'Lead',
    contact: 'Contact'
};

export async function sendPixelEvent(event, nombre, telefono, correo) {
    let body = null;
    if (event == FB_EVENTS.lead) {
        body = {
            data: [{
                event_name: event,
                event_time: Math.floor(Date.now() / 1000),
                user_data: {
                    em: [sha256(correo)],
                    ph: [sha256(telefono)],
                    fn: [sha256(nombre)]
                },
                event_source_url: location.href,
                action_source: 'website'
            }]
        };
    }
    else {
        body = {
            data: [{
                event_name: event,
                event_time: Math.floor(Date.now() / 1000),
                event_source_url: location.href,
                action_source: 'website'
            }]
        };
    }

    let raw = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });

    await raw.json();
}